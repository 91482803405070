import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the props type with children as ReactNode
interface DiscountProviderProps {
  children: ReactNode;
}

// Create context to hold the discounted amount
const DiscountContext = createContext<any>(null);

export const DiscountProvider: React.FC<DiscountProviderProps> = ({ children }) => {
  const [discountedAmount, setDiscountedAmount] = useState<number | null>(null);

  return (
    <DiscountContext.Provider value={{ discountedAmount, setDiscountedAmount }}>
      {children}
    </DiscountContext.Provider>
  );
};

// Hook to use the discount context
export const useDiscount = () => {
  return useContext(DiscountContext);
};
