export type countries =
	| 'Angola'
	| 'Nigeria'
	| 'Tanzania'
	| 'Togo'
	| 'Rwanda'
	| 'Namibia'
	| 'Ghana'
	| 'Sierra Leone'
	| 'Sweden'
	| 'UK';

export type state = Record<string, string[]>;
type CountryList = Record<countries, state[]>;
const ghanaStates: state[] = [
	{
		Ahafo: [
			'Bechem',
			'Duayaw Nkwantah',
			'Goaso',
			'Hwidiem',
			'Kenyasi',
			'Kukuom',
		],
		Ashanti: [
			'Kumasi',
			'Obuasi',
			'Tafo',
			'Ejura',
			'Nkawkaw',
			'Mampong',
			'Agogo',
			'Konongo',
			'Mpraeso',
			'Bekwai',
			'Abawe',
			'Abayisua',
			'Abease',
			'Abeasi',
			'Abejum',
			'Abene',
			'Abepawtia',
			'Abansua',
			'Abesewa',
			'Abetem',
			'Abetensu',
			'Abetensua',
			'Abetife',
			'Abetifi',
			'Abetim',
			'Abepotia',
			'Abanyaso',
			'Abanyasu',
			'Abase',
			'Abasi',
			'Abasua',
			'Abasuakuma',
			'Abasuapayin',
			'Abasukuma',
			'Abawdiem',
		],

		Bono: [
			'Amomaso',
			'Banda Ahenkro',
			'Berekum',
			'Benkasa',
			'Chiraa',
			'Dormaa Ahenkro',
			'Dormaa Akwamu',
			'Drobo',
			'Fiapre',
			'Jinijini',
			'New Drobo',
			'Nkrankwanta',
			'Nsawkaw',
			'Nsuatre',
			'Odumase',
			'Sampa',
			'Seikwa',
			'Sunyani',
			'Wamfie',
		],

		'Bono East': [
			'Atebubu',
			'Buipe',
			'Jema',
			'Kintampo',
			'Kwame Danso',
			'Nkoranza',
			'Prang',
			'Techiman',
			'Tuobodom',
			'Wenchi',
			'Yeji',
		],

		Central: [
			'Agona Swedru',
			'Amanfrom',
			'Anomabu',
			'Apam',
			'Bawjiase',
			'Breman Asikuma',
			'Budumburam',
			'Cape Coast',
			'Domeabra',
			'Elmina',
			'Foso',
			'Kasoa Ofaakor',
			'Kasoa Zongo',
			'Liberia Camp',
			'Mankessim',
			'Millennium City',
			'Mumford',
			'Nyakrom',
			'Nyananor',
			'Nyanyano',
			'Oduponkpehe',
			'Opeikuma',
			'Pentecost Seminary',
			'Saltpond',
			'Winneba',
		],
		Eastern: [
			'Abetifi',
			'Abompe',
			'Aburi',
			'Adeiso',
			'Adjeiikrom',
			'Adukrom',
			'Ahwerease',
			'Akim Oda',
			'Akim Swedru',
			'Akosombo',
			'Akropong',
			'Akwatia',
			'Akyem Hemang',
			'Amanokrom',
			'Apirede',
			'Asamankese',
			'Asesewa',
			'Atimpoku',
			'Ayiensu',
			'Begoro',
			'Bepoase',
			'Bisibom',
			'Bososo',
			'Brekusu',
			'Coaltar',
			'Dawu',
			'Dedesoworako',
			'Donkorkrom',
			'Dwenase',
			'Gyankama',
			'Juaso',
			'Kade',
			'Kibi',
			'Kitase',
			'Koforidua',
			'Kwabeng',
			'Mamfe',
			'Mampong',
			'Mpraeso',
			'New Abirem',
			'Nkawkaw',
			'Nsawam',
			'Nsutam',
			'Obooho',
			'Obosomase',
			'Odumase Krobo',
			'Ofoase',
			'Osino',
			'Peduase',
			'Saaman',
			'Somanya',
			'Summer',
			'Suhum',
			'Tease',
			'Tutu',
		],

		'Greater Accra': [
			'Abelemkpe',
			'Ablekuma',
			'Abokobi',
			'Abossey Okai',
			'Accra Newtown',
			'Achimota',
			'Adabraka',
			'Adenta',
			'Afienya',
			'Agbogba',
			'Airport',
			'Amasaman',
			'Ashiaman',
			'Ashongman',
			'Aslyum Down',
			'Atico',
			'Baatsona - Spintex',
			'Bortianor',
			'Cantonment',
			'Dansoman',
			'Darkuman',
			'Dawhenya',
			'Dodowa',
			'Dome',
			'Dzorwulu',
			'East Legon',
			'Gbawe',
			'Haatso',
			'James Town',
			'Kanda',
			'Kaneshie',
			'Kasoa',
			'Kissieman',
			'Kokrobite',
			'Korle Bu',
			'Kpone',
			'Kwabenya',
			'Kwashieman',
			'Labadi',
			'Labone',
			'Lapaz',
			'Lartebiokorshie',
			'Lashibi',
			'Legon',
			'Madina',
			'Makola',
			'Mallam',
			'McCarthy Hill',
			'Michel Camp',
			'Nima',
			'Nungua',
			'Oblogo Mallam',
			'Odoponkpehe',
			'Odorkor',
			'Osu',
			'Oyarifa',
			'Patang',
			'Prampram',
			'Ridge',
			'Roman Ridge',
			'Sakumono',
			'Santa Maria',
			'Sowutuom',
			'Taifa',
			'Tema',
			'Tema New Town',
			'Tesano',
			'Teshie',
			'Tetegu',
			'Tieman',
			'Tudu',
			'Weija',
			'Westhills',
		],

		'North East': ['Chereponi', 'Gambaga', 'Nalerigu', 'Walewale'],

		Northern: [
			'Bimbila',
			'Gushiegu',
			'Kpandae',
			'Saboba',
			'Salaga',
			'Tamale',
			'Tatale',
			'Wulensi',
			'Yendi',
		],

		Oti: [
			'Akabiem',
			'Bagjamse',
			'Borae',
			'Buafori',
			'Burai',
			'Chindiri',
			'Dambai',
			'Guibi',
			'Kradente',
			'Okorede',
			'Zongo Macheri',
			'Jasikan',
			'Kadjebi',
			'Kete Krachi',
			'Kpassa',
			'Nkonya',
		],

		Savannah: ['Bole', 'Buipe', 'Damango', 'Salaga', 'Sawla', 'Tolon'],

		'Upper East': [
			'Bawku',
			'Bolgatanga',
			'Navrongo',
			'Abilitega',
			'Abliri',
			'Acharaba',
			'Adaboya',
			'Adokobisi',
			'Adonsi',
			'Adoweko',
			'Adunania',
			'Adung',
			'Aeopea',
			'Agago',
			'Agushi',
			'Akamo',
			'Akanaba',
			'Abeu',
			'Akulmasa',
			'Akunda',
			'Akundaw',
			'Akundawi',
			'Akunduo',
			'Akunka',
			'Akunkogo',
			'Akunkongo',
			'Akunkongu',
			'Akure',
			'Akuri',
			'Alagiligobisi',
			'Alenzua',
			'Alidem',
			'Akorisi',
			'Abilatega',
			'Abileteiga',
			'Bongo',
			'Navrongo',
			'Paga',
			'Tongo',
		],
		'Upper West': [
			'Wa',
			'Arumon',
			'Baayiri',
			'Baazu',
			'Babile',
			'Babilli',
			'Babujan',
			'Babweasan',
			'Baghari',
			'Bagri',
			'Baire',
			'Bala',
			'Balafile',
			'Balie',
			'Bamkpoma',
			'Bandei',
			'Amburi',
			'Bangwon',
			'Banko',
			'Bankpama',
			'Banla',
			'Bano',
			'Banosan',
			'Bantala',
			'Banu',
			'Barewon',
			'Basabli',
			'Base',
			'Basebli',
			'Basiasan',
			'Basirisan',
			'Basisan',
			'Bawiesibal',
			'Bangmo',
			'Funsi',
			'Gwollu',
			'Issa',
			'Jirapa',
			'Kaleo',
			'Lambussie',
			'Lawra',
			'Nadowli',
			'Nandom',
			'Tumu',
			'Wa',
			'Wechiau',
		],
		Volta: [
			'Tema',
			'Teshie',
			'Nungua',
			'Madina',
			'Ho',
			'Hohoe',
			'Aflao',
			'Anloga',
			'Nsawam',
			'Suhum',
			'Begoro',
			'Kpandu',
			'Keta',
			'Aburi',
			'Akropong',
			'Ablibukope',
			'Ablibukorpe',
			'Abetia',
			'Ablonu',
			'Abluno',
			'Ablimen',
			'Abetinsi',
			'Abetinso',
			'Abia',
			'Abiriu',
			'Abiriw',
			'Ablajei',
			'Ablamuzadu',
			'Ablanfedo',
			'Ablefu',
			'Ablekpui',
			'Ablekuma',
			'Ablemkpe',
			'Ablenkpe',
			'Ablevenia',
		],
		Western: [
			'Abase',
			'Aboso',
			'Adiawoso',
			'Adiembra',
			'Adwowa',
			'Agona Nkwanta',
			'Agufo',
			'Ahonozo',
			'Akatachi',
			'Alabokazo',
			'Anaji',
			'Apowa',
			'Apremodo',
			'Asankragua',
			'Awukyere',
			'Axim',
			'Beahu',
			'Bogoso',
			'Butumagyabu',
			'Busua',
			'Daboase',
			'Dadwen',
			'Diabene',
			'Dixcove',
			'East Tanokrom',
			'Effiakuma',
			'Eikwe',
			'Elubo',
			'Essiama',
			'Essikado',
			'Essipong',
			'Ewusiejo',
			'Fijai',
			'Funko',
			'Half Assini',
			'Hotopo',
			'Inchaban',
			'kansaworado',
			'Kejabil',
			'Kojokrom',
			'Kwawkrom',
			'Kweikuma',
			'kwesimintsim',
			'Manso',
			'Miemia',
			'Mpintsin',
			'New Akwidaa',
			'New Amanfu',
			'New Takoradi',
			'Ngyiresia',
			'Nkroful',
			'Nsein',
			'Ntankoful',
			'Nzulezo',
			'Old Akwidaa',
			'Prestea',
			'Princess Town',
			'Samreboi',
			'Sekondi',
			'Shama',
			'Takoradi',
			'Tarkwa',
			'Tikobo No.1',
			'Tikobo No.2',
			'West Tanokrom',
			'Windy Ridge',
		],

		'Western North': [
			'Attankona',
			'Nkatieso',
			'Abochia',
			'Abusam-kwawu',
			'Achimfo',
			'Acquai Allah',
			'Adabokrom',
			'Adamase',
			'Adiembra',
			'Adjakaa',
			'Adjoafua',
			'Adukrom',
			'Afere',
			'Aferewa',
			'Agya Mensahkrom',
			'Ahibenso',
			'Akaaso',
			'Akaatiso',
			'Akontombra',
			'Akronsu',
			'Amadukrom',
			'Amoaya',
			'Amonie',
			'Anhwiafutu',
			'Anhwiaso',
			'Ankra-Muano',
			'Antobia',
			'Apronsee Mission',
			'Asawinso',
			'Asemkrom',
			'Asikrom (Ensonnyameye)',
			'Asuontaa',
			'Asuopiri',
			'Awaso',
			'Ayidam',
			'Benchema',
			'Benomsuo',
			'Bethlehem',
			'Bibiani',
			'Bibiani',
			'Bodi',
			'Boison',
			'Boizan',
			'Bokabo',
			'Bonsu Nkwanta',
			'Car Owner',
			'Chirano',
			'Dadieso',
			'Denchemuosue',
			'Disueano',
			'Dominibo',
			'Elluokrom',
			'Enchi',
			'Enchi',
			'Essam Debiso',
			'Goka',
			'Gyema',
			'Hene Nkwanta',
			'Homgyebre',
			'Hwenampori',
			'Jensue',
			'Juabeso Nkwanta',
			'Juabeso',
			'Juaboso',
			'kaase',
			'Kakanabo',
			'Kama',
			'Kankyiabo',
			'Kantakrobo',
			'Kefass',
			'Kofi Krah',
			'Kofikrom',
			'Kojoaba',
			'Kojofosukrom',
			'Kotosaa',
			'Kramokrom',
			'Kunkumso',
			'Kwafukaa',
			'Kwaku Kyerekrom',
			'Kwame Adjei Krom',
			'Kwamebikrom',
			'Kwawu',
			'Kwesikrom',
			'Kyerkyewere',
			'Mafia',
			'Mangoase-Punpunso',
			'Mangoase',
			'Mayera',
			'Moshiehene Krom',
			'New Abesim',
			'New Achiase',
			'New Nsuatre',
			'New Yakasi',
			'Nkrabea',
			'Nkwanta',
			'Nso Nyame Ye (Mireku)',
			'Nsowakrom',
			'Ntesano',
			'Nyamebekyere No.1',
			'Nyankamam',
			'Okrayawkrom',
			'Old Debiso',
			'Old Yakasi',
			'Omanpe',
			'Oseikojokrom',
			'Osumanikrom',
			'Owusu Krom',
			'Patakro',
			'Pramprase',
			'Proso',
			'Sayerano',
			'Sefwi Anhwiaso',
			'Sefwi Bekwai',
			'Sewum',
			'Sonka No.1',
			'Subri Nkwanta (Kojina)',
			'Subri',
			'Suiano',
			'Surano',
			'Tanoso-Praso',
			'Wenchi',
			'Yawmatwa',
			'Yiwabra',
		],
	},
];
const nigeriaStates: state[] = [
	{
		Abia: [
			'Aba',
			'Akwete',
			'Arochukwu',
			'Bende',
			'Ebem Ohafia',
			'Isiala Oboro',
			'Mbalano',
			'Mgboko',
			'Nkwoagu Isuochi',
			'Oke-Ikpe',
			'Okpuala-Ngwa',
			'Omoba',
			'Osisioma',
			'Umuahia',
		],

		'Abuja Federal Capital Territory': [
			'Abaji',
			'Abuja',
			'Bwari',
			'Gwagwalada',
			'Kuje',
			'Kwali',
		],

		Adamawa: [
			'Demsa',
			'Fufore',
			'Ganye',
			'Gella',
			'Girei',
			'Gombi',
			'Gulak',
			'Guyuk',
			'Hong',
			'Jada',
			'Jimeta',
			'Lamurde',
			'Maiha',
			'Mayo-Belwa',
			'Michika',
			'Mubi',
			'Numan',
			'Shelleng',
			'Song',
			'Toungo',
			'Yola',
		],

		'Akwa Ibom': [
			'Abak',
			'Abat',
			'Afaha Ikot Ebak',
			'Afaha Offiong',
			'Eket',
			'Enwang',
			'Etinan',
			'Eyofin',
			'Ibiaku Ntok Okpo',
			'Idu',
			'Ikot Abasi',
			'Ikot Akpa Nkuk',
			'Ikot Edibon',
			'Ikot Ekpene',
			'Ikot Ibritam',
			'Itu',
			'Mkpat Enin',
			'Nto Edino',
			'Nung Udoe',
			'Odoro Ikpe',
			'Odot',
			'Oko Ita',
			'Okopedi',
			'Okoroete',
			'Oron',
			'Upenekang',
			'Uquo',
			'Urua Inyang',
			'Urue Offong',
			'Utu Etim Ekpo',
			'Uyo',
		],

		Anambra: [
			'Abagana',
			'Achalla',
			'Aguata',
			'Ajalli',
			'Anaku',
			'Atani',
			'Awka',
			'Ihiala',
			'Neni',
			'Nnewi',
			'Nteje',
			'Nzam',
			'Ogidi',
			'Ojoto',
			'Onitsha',
			'Otuocha',
			'Ozubulu',
			'Ukpo',
			'Ukpor',
			'Umunze',
		],

		Bauchi: [
			'Alkaleri',
			'Azare',
			'Bauchi',
			'Bogoro',
			'Dambam',
			'Darazo',
			'Dass',
			'Gamawa',
			'Giade',
			'Itas',
			'Jama’are',
			'Kafin Madaki',
			'Katagum',
			'Kirfi',
			'Misau',
			'Ningi',
			'Tafawa Balewa',
			'Toro',
			'Warji',
			'Yana',
		],

		Bayelsa: [
			'Ekeremor',
			'Kaiama',
			'Nembe',
			'Ogbia',
			'Oporoma',
			'Sagbama',
			'Twon-Brass',
			'Yenagoa',
		],

		Benue: [
			'Adikpo',
			'Aliade',
			'Buruku',
			'Gbajimba',
			'Gboko',
			'Igumale',
			'Katsina-Ala',
			'Lessel',
			'Makurdi',
			'Naka',
			'Obagaji',
			'Obarike-Ito',
			'Oju',
			'Okpoga',
			'Otukpa',
			'Otukpo',
			'Sankera',
			'Tse-Agberagba',
			'Ugba',
			'Ugbokpo',
			'Vandeikya',
			'Wannune',
		],

		Borno: [
			'Askira',
			'Azare',
			'Bama',
			'Benisheikh',
			'Biu',
			'Briyel',
			'Chibok',
			'Damasak',
			'Damboa',
			'Dikwa',
			'Gajiram',
			'Gamboru',
			'Gubio',
			'Gudumbali',
			'Gwoza',
			'Khaddamari',
			'Konduga',
			'Kukawa',
			'Kwaya Kusar',
			'Mafa',
			'Magumeri',
			'Maiduguri',
			'Mallam Fatori',
			'Marte',
			'Monguno',
			'Rann',
			'Shani',
		],

		'Cross River': [
			'Abuochiche',
			'Akamkpa',
			'Akpet Central',
			'Boje',
			'Calabar',
			'Effraya',
			'Ikom',
			'Ikot Nakanda',
			'Itigidi',
			'Obubra',
			'Obudu',
			'Odukpani',
			'Ogoja',
			'Okpoma',
			'Sankwala',
			'Ugep',
		],

		Delta: [
			'Aboh',
			'Agbor',
			'Akwukwu-Igbo',
			'Asaba',
			'Bomadi',
			'Burutu',
			'Effurun',
			'Isiokolo',
			'Issele-Uku',
			'Koko',
			'Kwale',
			'Obiaruku',
			'Ogbe-Ijoh',
			'Oghara',
			'Ogwashi-Uku',
			'Oleh',
			'Orerokpe',
			'Otor-Udu',
			'Otu-Jeremi',
			'Owa-Oyibu',
			'Ozoro',
			'Patani',
			'Sapele',
			'Ughelli',
			'Warri',
		],

		Ebonyi: [
			'Abakaliki',
			'Afikpo',
			'Ezillo',
			'Ezzamgbo',
			'Iboko',
			'Isiaka',
			'Isu',
			'Nguzu Edda',
			'Obiozara',
			'Onuebonyi Echara',
			'Onueke',
			'Ugbodo',
		],

		Edo: [
			'Abudu',
			'Afuze',
			'Agenebode',
			'Auchi',
			'Benin City',
			'Ehor',
			'Ekpoma',
			'Fugar',
			'Idogbo',
			'Igarra',
			'Igueben',
			'Iguobazuwa',
			'Irrua',
			'Okada',
			'Sabongida-Ora',
			'Ubiaja',
			'Uromi',
			'Uselu',
		],

		Ekiti: [
			'Ado-Ekiti',
			'Aramoko-Ekiti',
			'Efon-Alaaye',
			'Emure-Ekiti',
			'Ido-Ekiti',
			'Igede-Ekiti',
			'Ijero-Ekiti',
			'Ikere-Ekiti',
			'Ikole-Ekiti',
			'Ilawe-Ekiti',
			'Ise-Ekiti',
			'Iye-Ekiti',
			'Ode-Ekiti',
			'Omuo-Ekiti',
			'Otun-Ekiti',
			'Oye-Ekiti',
		],

		Enugu: [
			'Agbani',
			'Aguobu-Owa',
			'Amagunze',
			'Awgu',
			'Enugu',
			'Enugu-Ezike',
			'Ibagwa-Aka',
			'Ikem',
			'Ndeaboh',
			'Nkwo Nike',
			'Nsukka',
			'Obollo-Afor',
			'Ogbede',
			'Oji River',
			'Udi',
			'Umulona',
		],

		Gombe: [
			'Bajoga',
			'Billiri',
			'Boh',
			'Deba',
			'Dukku',
			'Gombe',
			'Kaltungo',
			'Kumo',
			'Mallam Sidi',
			'Nafada',
			'Talasse',
		],

		Imo: [
			'Aboh',
			'Afor-Oru',
			'Amaigbo',
			'Awo-Idemili',
			'Dikenafai',
			'Egbema',
			'Iho',
			'Isinweke',
			'Itu',
			'Mgbidi',
			'Nkwerre',
			'Nnenasa',
			'Nwaorieubi',
			'Oguta',
			'Okigwe',
			'Okwe',
			'Orlu',
			'Owerri',
			'Umuelemai',
			'Umuguma',
			'Umumma',
			'Umundugba',
			'Umuneke-Ngor',
			'Urualla',
		],

		Jigawa: [
			'Auyo',
			'Babura',
			'Birnin Kudu',
			'Birniwa',
			'Dutse',
			'Gagarawa',
			'Gantsa',
			'Garki',
			'Gumel',
			'Guri',
			'Gwaram',
			'Gwiwa',
			'Hadejia',
			'Jahun',
			'Kafin Hausa',
			'Karkarna',
			'Kaugama',
			'Kazaure',
			'Kiri Kasamma',
			'Kiyawa',
			'Maigatari',
			'Malam Madori',
			'Miga',
			'Ringim',
			'Roni',
			'Sule Tankarkar',
			'Taura',
		],

		Kaduna: [
			'Anchau',
			'Birnin Gwari',
			'Giwa',
			'Gwantu',
			'Hunkuyi',
			'Ikara',
			'Kachia',
			'Kaduna',
			'Kafanchan',
			'Kagarko',
			'Kajuru',
			'Kaura',
			'Kauru',
			'Kujama',
			'Kwoi',
			'Maigana',
			'Makarfi',
			'Makera',
			'Sabon Gari',
			'Saminaka',
			'Turunku',
			'Zaria',
			'Zonkwa',
		],

		Kano: [
			'Ajingi',
			'Albasu',
			'Bagwai',
			'Bebeji',
			'Bichi',
			'Bunkure',
			'Dambatta',
			'Dawakin Kudu',
			'Dawakin Tofa',
			'Garko',
			'Garun Malam',
			'Gaya',
			'Gezawa',
			'Gwarzo',
			'Kabo',
			'Kano',
			'Karaye',
			'Kibiya',
			'Kiru',
			'Koguna',
			'Kumbotso',
			'Kunchi',
			'Kura',
			'Madobi',
			'Minjibir',
			'Rano',
			'Rimin Gado',
			'Ririwai',
			'Rogo',
			'Shanono',
			'Sumaila',
			'Takai',
			'Tofa',
			'Tsanyawa',
			'Tudun Wada',
			'Ungogo',
			'Warawa',
			'Wudil',
			'Zakirai',
		],

		Katsina: [
			'Bakori',
			'Batagarawa',
			'Batsari',
			'Baure',
			'Bindawa',
			'Charanchi',
			'Dan Musa',
			'Dandume',
			'Danja',
			'Daura',
			'Dutsi',
			'Dutsin-Ma',
			'Faskari',
			'Funtua',
			'Ingawa',
			'Jibia',
			'Kafur',
			'Kaita',
			'Kankara',
			'Kankia',
			'Katsina',
			'Kurfi',
			'Kusada',
			'Mai’Adua',
			'Malumfashi',
			'Mani',
			'Mashi',
			'Matazu',
			'Musawa',
			'Rimi',
			'Sabuwa',
			'Safana',
			'Sandamu',
			'Zango',
		],

		Kebbi: [
			'Aliero',
			'Argungu',
			'Augie',
			'Bagudo',
			'Besse',
			'Birnin Kebbi',
			'Bunza',
			'Dakingari',
			'Gwandu',
			'Jega',
			'Kalgo',
			'Kamba',
			'Kangiwa',
			'Mahuta',
			'Maiyama',
			'Ribah',
			'Sakaba',
			'Shanga',
			'Wara',
			'Yelwa',
			'Zuru',
		],

		Kogi: [
			'Abejukolo',
			'Adogo',
			'Ajaka',
			'Akpafa',
			'Ankpa',
			'Dekina',
			'Idah',
			'Isanlu',
			'Iyara',
			'Kabba',
			'Koton-Karfe',
			'Lokoja',
			'Mopa',
			'Obangede',
			'Odo-Ere',
			'Ogaminana',
			'Oguma',
			'Okene',
			'Okpo',
			'Onyedega',
			'Ugwolawo',
		],

		Kwara: [
			'Afon',
			'Araromi-Opin',
			'Bode Saadu',
			'Fufu',
			'Ilemona',
			'Iloffa',
			'Ilorin',
			'Kaiama',
			'Kosubosu',
			'Lafiagi',
			'Offa',
			'Oke-Oyi',
			'Omu-Aran',
			'Owu-Isin',
			'Patigi',
			'Share',
		],

		Lagos: [
			'Agbado-Oke Odo',
			'Ketu',
			'Apapa Iganmu',
			'Ayobo-Ipaja',
			'Badagry West',
			'Bariga',
			'Coker-Aguda',
			'Egbe-Idimu',
			'Ejigbo',
			'Eredo',
			'Eti-Osa East',
			'Iba',
			'Ifelodun',
			'Igando-Ikotun',
			'Igbogbo Baiyeku',
			'Ijede',
			'Ikorodu North',
			'Ikorodu West',
			'Ikosi Ejinrin',
			'Ikosi/Isheri',
			'Ikoyi/Obalende',
			'Imota',
			'Iru',
			'Victoria Island',
			'Isolo',
			'Itire-Ikate',
			'Lagos Island East',
			'Lekki',
			'Mosan-Okunola',
			'Odi-Olowo',
			'Ojodu',
			'Ojokoro',
			'Olorunda',
			'Onigbongbo',
			'Oriade',
			'Orile Agege',
			'Oto Awori',
			'Yaba',
		],

		Nasarawa: [
			'Akwanga',
			'Awe',
			'Doma',
			'Garaku',
			'Karu',
			'Keana',
			'Keffi',
			'Lafia',
			'Nasarawa',
			'Nasarawa Egon',
			'Obi',
			'Toto',
			'Wamba',
		],

		Niger: [
			'Agaie',
			'Agwara',
			'Bangi',
			'Bida',
			'Enagi',
			'Gawu Babangida',
			'Kagara',
			'Katcha',
			'Kontagora',
			'Kuta',
			'Kutigi',
			'Lapai',
			'Lemu',
			'Maikunkele',
			'Mashegu',
			'Minna',
			'Mokwa',
			'Nasko',
			'New Bussa',
			'Paiko',
			'Rijau',
			'Sabon Wuse',
			'Sarkin Pawa',
			'Suleja',
			'Wushishi',
		],

		Ogun: [
			'Abeokuta',
			'Abigi',
			'Atan',
			'Ayetoro',
			'Ifo',
			'Ijebu-Igbo',
			'Ijebu-Ode',
			'Ikenne',
			'Ilaro',
			'Imeko',
			'Ipokia',
			'Isara',
			'Itori',
			'Odeda',
			'Odogbolu',
			'Ogbere',
			'Ota',
			'Owode',
			'Shagamu',
		],

		Ondo: [
			'Akure',
			'Bolorunduro',
			'Ifon',
			'Igbara-Oke',
			'Igbekebo',
			'Igbokoda',
			'Iju',
			'Ikare',
			'Ile-Oluji',
			'Isua',
			'Ita-Ogbolu',
			'Ode-Irele',
			'Oka',
			'Oke-Agbe',
			'Okitipupa',
			'Ondo',
			'Ore',
			'Owena',
			'Owo',
		],

		Osun: [
			'Apomu',
			'Awo',
			'Bode Osi',
			'Ede',
			'Ejigbo',
			'Gbongan',
			'Ibokun',
			'Ifetedo',
			'Ifon',
			'Ijebu-Jesa',
			'Ikire',
			'Ikirun',
			'Ila Orangun',
			'Ile-Ife',
			'Ile-Ogbo',
			'Ilesa',
			'Ilobu',
			'Iperindo',
			'Ipetumodu',
			'Iragbiji',
			'Iwo',
			'Oke-Ila',
			'Okuku',
			'Osogbo',
			'Osu',
			'Otan Ayegbaju',
		],

		Oyo: [
			'Ago-Amodu',
			'Ajaawa',
			'Akanran',
			'Ayete',
			'Egbeda',
			'Eruwa',
			'Ibadan',
			'Idi-Ayunre',
			'Ido',
			'Igbeti',
			'Igboho',
			'Igbo-Ora',
			'Ikoyi-Ile',
			'Iresa-Adu',
			'Iseyin',
			'Iwere-Ile',
			'Iyana-Ofa',
			'Jobele',
			'Kisi',
			'Moniya',
			'Ogbomoso',
			'Okeho',
			'Otu',
			'Oyo',
			'Saki',
			'Tede',
		],

		Plateau: [
			'Angware',
			'Baap',
			'Barkin Ladi',
			'Bassa',
			'Bokkos',
			'Bukuru',
			'Dengi',
			'Jos',
			'Kwal',
			'Langtang',
			'Mabudi',
			'Mangu',
			'Pankshin',
			'Riyom',
			'Shendam',
			'Tunkus',
			'Wase',
		],

		Rivers: [
			'Abonnema',
			'Abua',
			'Afam',
			'Ahoada',
			'Akinima',
			'Bonny',
			'Bori',
			'Buguma',
			'Degema',
			'Eberi',
			'Emuoha',
			'Isiokpo',
			'Kpor',
			'Nchia',
			'Ngo',
			'Ogu',
			'Okehi',
			'Okrika',
			'Omoku',
			'Opobo',
			'Port Harcourt',
			'Rumuodomaya',
			'Saakpenwa',
		],

		Sokoto: [
			'Balle',
			'Binji',
			'Bodinga',
			'Dange',
			'Gada',
			'Gidan Madi',
			'Goronyo',
			'Gwadabawa',
			'Illela',
			'Isa',
			'Kebbe',
			'Kware',
			'Rabah',
			'Sabon Birni',
			'Shagari',
			'Silame',
			'Sokoto',
			'Tambuwal',
			'Tureta',
			'Wamako',
			'Wurno',
			'Yabo',
		],

		Taraba: [
			'Baissa',
			'Bali',
			'Donga',
			'Gembu',
			'Ibi',
			'Jalingo',
			'Karim Lamido',
			'Lau',
			'Lissam',
			'Mutum Biyu',
			'Pantisawa',
			'Serti',
			'Sunkani',
			'Takum',
			'Wukari',
			'Zing',
		],

		Yob: [
			'Babban Gida',
			'Bara',
			'Buni Yadi',
			'Damagum',
			'Damaturu',
			'Dapchi',
			'Fika',
			'Gashua',
			'Geidam',
			'Jajimaji',
			'Jakusko',
			'Kanamma',
			'Machina',
			'Nguru',
			'Potiskum',
			'Sabon Garin Nangere',
			'Yusufari',
		],

		Zamfara: [
			'Anka',
			'Bakura',
			'Birnin Magaji',
			'Bukkuyum',
			'Bungudu',
			'Gummi',
			'Gusau',
			'Kaura Namoda',
			'Maradun',
			'Maru',
			'Shinkafi',
			'Talata Mafara',
			'Tsafe',
			'Zurmi',
		],
	},
];
const tanzaniaStates: state[] = [
	{
		Arusha: [
			'Arusha',
			'Kingori',
			'Kiratu',
			'Longido',
			'Mbuguni',
			'Merelani',
			'Meru',
			'Monduli',
			'Mto wa Mbu',
			'Namanga',
			'Ngorongoro',
			'Nkoaranga',
			'Poli',
			'Usa River',
		],

		'Dar es Salaam': ['Dar es Salaam', 'Magomeni'],

		Dodoma: [
			'Dodoma',
			'Kibakwe',
			'Kisasa',
			'Kondoa',
			'Kongwa',
			'Mpwapwa',
			'Msanga',
		],

		Geita: [
			'Buseresere',
			'Chato',
			'Geita',
			'Kasamwa',
			'Katoro',
			'Masumbwe',
			'Ushirombo',
			'Uyovu',
		],

		Iringa: ['Ilula', 'Iringa', 'Izazi', 'Mafinga', 'Makungu', 'Malangali'],

		Kagera: [
			'Biharamulo',
			'Bugarama',
			'Bugene',
			'Bukoba',
			'Kabanga',
			'Kamachumu',
			'Katerero',
			'Katoro',
			'Kyaka',
			'Ngara',
			'Nshamba',
			'Nsunga',
			'Nyakahanga',
			'Rulenge',
		],

		Katavi: ['Inyonga', 'Karema', 'Mpanda', 'Usevia'],

		Kigoma: [
			'Kakonko',
			'Kasulu',
			'Kibondo',
			'Kigoma',
			'Mabamba',
			'Mwandiga',
			'Nguruka',
			'Uvinza',
		],

		Kilimanjaro: [
			'Hedaru',
			'Kihurio',
			'Kisiwani',
			'Kwakoa',
			'Lembeni',
			'Makanya',
			'Moshi',
			'Mwembe',
			'Ndungu',
			'Same',
		],

		Lindi: [
			'Lindi',
			'Mbekenyera',
			'Mingoyo',
			'Mtama',
			'Nachingwea',
			'Nyangao',
			'Ruangwa',
		],

		Manyara: [
			'Babati',
			'Bashanet',
			'Basotu',
			'Dareda',
			'Dongobesh',
			'Endasak',
			'Galappo',
			'Katesh',
			'Kibaya',
			'Kirya',
			'Magugu',
			'Mbulu',
			'Naberera',
			'Nangwa',
		],

		Mara: [
			'Bukonyo',
			'Butiama',
			'Issenye',
			'Kibara',
			'Mugango',
			'Mugumu',
			'Muriti',
			'Musoma',
			'Nakatunguru',
			'Nansio',
			'Nyamuswa',
			'Sirari',
		],

		Mbeya: [
			'Chimala',
			'Hedaru',
			'Ibaba',
			'Ikama',
			'Ikinga',
			'Ikolo',
			'Ikuti',
			'Ilembo',
			'Ipinda',
			'Kabula',
			'Kahe Tanzania',
			'Kandete',
			'Katumba',
			'Kihurio',
			'Kisiwani',
			'Kiwira',
			'Kwakoa',
			'Lembeni',
			'Lupata',
			'Lusungo',
			'Machame',
			'Makanya',
			'Marangu',
			'Masukulu',
			'Mbeya',
			'Mlowo',
			'Moshi',
			'Mpuguso',
			'Mwansanga',
			'Mwaya',
			'Mwembe',
			'Same',
			'Tukuyu',
			'Tunduma',
			'Ugweno',
			'Ulanga',
		],

		Morogoro: [
			'Geiro',
			'Ifakara',
			'Kidatu',
			'Kidodi',
			'Kilosa',
			'Kimamba',
			'Kisanga',
			'Lupiro',
			'Magole',
			'Mahenge',
			'Malinyi',
			'Mikumi',
			'Mlimba',
			'Morogoro',
			'Msowero',
			'Mtimbira',
			'Mvomero District',
			'Ngerengere',
		],

		Mtwara: [
			'Chiungutwa',
			'Kitama',
			'Kitangari',
			'Luchingu',
			'Lukuledi',
			'Lulindi',
			'Madimba',
			'Mahuta',
			'Masasi',
			'Masuguru',
			'Mtwara',
			'Namalenga',
			'Namikupa',
			'Nanganga',
			'Nangomba',
			'Nanhyanga',
			'Nanyamba',
			'Newala Kisimani',
			'Tandahimba',
		],

		Mwanza: [
			'Ilemela District',
			'Kihangara',
			'Malya',
			'Misasi',
			'Misungwi',
			'Mwanza',
			'Ngudu',
			'Nyanguge',
			'Usagara',
		],

		Njombe: [
			'Ilembula',
			'Makumbako',
			'Manda',
			'Matamba',
			'Mlangali',
			'Mtwango',
			'Njombe',
		],

		'Pemba North': ['Konde', 'Micheweni', 'Wete'],

		'Pemba South': ['Chake Chake', 'Mtambile', 'Uwelini'],

		Pwani: [
			'Bagamoyo',
			'Bungu',
			'Chalinze',
			'Ikwiriri',
			'Kibaha',
			'Kibiti',
			'Kilindoni',
			'Kisarawe',
			'Lugoba',
			'Maneromango',
			'Mbumi',
			'Mkuranga',
			'Mlandizi',
			'Mvomero',
			'Utete',
			'Vikindu',
		],

		Rukwa: [
			'Chala',
			'Kirando',
			'Laela',
			'Matai',
			'Namanyere',
			'Nkove',
			'Sumbawanga',
		],

		Ruvuma: [
			'Kigonsera',
			'Liuli',
			'Mahanje',
			'Maposeni',
			'Matiri',
			'Mbamba Bay',
			'Mbinga',
			'Songea',
			'Tingi',
		],

		Shinyanga: [
			'Isaka',
			'Kahama',
			'Kishapu',
			'Mhango',
			'Mwadui',
			'Old Shinyanga',
			'Shinyanga',
			'Songwa',
			'Tinde',
		],

		Simiyu: [
			'Bariadi',
			'Kisesa',
			'Lalago',
			'Malampaka',
			'Maswa',
			'Matonga',
			'Nyakabindi',
			'Nyalikungu',
			'Somanda',
		],

		Singida: [
			'Igugunu',
			'Ikungi',
			'Ilongero',
			'Itigi',
			'Kilimatinde',
			'Kintinku',
			'Kiomboi',
			'Mgandu',
			'Mtinko',
			'Mungaa',
			'Ndago',
			'Puma',
			'Sepuka',
			'Shelui',
			'Singida',
		],

		Songwe: ['Songwe'],

		Tabora: [
			'Bukene',
			'Igurubi',
			'Kaliua',
			'Mabama',
			'Sikonge',
			'Tabora',
			'Tumbi',
			'Usoke',
		],

		Tanga: [
			'Chanika',
			'Lushoto',
			'Magomeni',
			'Majengo',
			'Makuyuni',
			'Maramba',
			'Matui',
			'Mazinde',
			'Mlalo',
			'Muheza',
			'Mwanga',
			'Pangani',
			'Soni',
			'Tanga',
		],

		'Zanzibar North': ['Gamba', 'Kijini', 'Kiwengwa', 'Mkokotoni', 'Nungwi'],

		'Zanzibar South': ['Koani', 'Koani Ndogo', 'Mahonda', 'Nganane', 'Sokoni'],

		'Zanzibar West': ['Zanzibar'],
	},
];
const rwandaStates: state[] = [
	{
		'Eastern Province': ['Kibungo', 'Rwamagana'],
		'Kigali district': ['Kigali'],
		'Northern Province': ['Byumba', 'Musanze'],
		'Southern Province': [
			'Butare',
			'Eglise Catholique Centrale GIKO',
			'Gitarama',
			'Nzega',
		],
		'Western Province': ['Cyangugu', 'Gisenyi', 'Kibuye'],
	},
];
const namibiaStates: state[] = [
	{
		'Erongo Region': [
			'Arandis',
			'Hentiesbaai',
			'Karibib',
			'Omaruru',
			'Otjimbingwe',
			'Swakopmund',
			'Swakopmund Constituency',
			'Usakos',
			'Walvis Bay',
		],
		'Hardap Region': [
			'Aranos',
			'Hoachanas',
			'Maltahöhe',
			'Mariental',
			'Rehoboth',
		],
		'Karas Region': [
			'Bethanie',
			'Karasburg',
			'Keetmanshoop',
			'Lüderitz',
			'Oranjemund',
			'Tses',
			'Warmbad',
		],
		'Kavango East Region ': ['Rundu'],
		'Kavango West Region ': ['Kavango West Region'],
		'Khomas Region': ['Katutura', 'Windhoek'],
		'Kavango East Region': [
			'Epupa Constituency',
			'Khorixas',
			'Khorixas Constituency',
			'Opuwo',
			'Opuwo Constituency',
			'Outjo',
			'Sesfontein Constituency',
		],
		'Ohangwena Region': ['Oshikango'],
		'Omaheke Region': ['Gobabis'],
		'Omusati Region': ['Okahao', 'Ongandjera', 'Outapi'],
		'Oshana Regio': ['Ondangwa', 'Ongwediva', 'Oshakati'],
		'Oshikoto Region': ['Omuthiya', 'Tsumeb'],
		'Otjozondjupa Region': [
			'Grootfontein',
			'Okahandja',
			'Okakarara',
			'Otavi',
			'Otjiwarongo',
		],
		'Zambezi Region': ['Bagani', 'Katima Mulilo'],
	},
];
const angolaStates: state[] = [
	{
		Bengo: ['Caxito', 'Ambriz'],
		Benguela: ['Lobito', 'Cubal', 'Ganda', 'Catumbela', 'Benguela'],
		Bié: ['Catabola', 'Cuito', 'Camacupa', 'Calenga'],
		Cabinda: ['Cabinda'],
		Cunene: ['Ondjiva', 'Tchitado', 'Xangongo'],
		Huambo: ['Huambo', 'Vila Teixeira da Silva', 'Caála', 'Longonjo'],
		Huíla: [
			'Lubango',
			'Matala',
			'Catape',
			'Quilengues',
			'Caluquembe',
			'Chibemba',
			'Chibia',
		],
		'Kuando Kubango': ['Menongue', 'Cuito Cuanavale', 'Mavinga'],
		'Kwanza Norte': ['Ndalatando', 'Camabatela', 'Dondo'],
		'Kwanza Sul': [
			'Sumbe',
			'Porto Amboim',
			'Cassongue',
			'Uacu Cungo',
			'Quibala',
			'Calulo',
		],
		Luanda: ['Luanda', 'Belas'],
		'Lunda Norte': ['Lucapa', 'Capenda Camulemba', 'Dundo'],
		'Lunda Sul': ['Saurimo', 'Muconda', 'Cacolo'],
		Malanje: ['Malanje'],
		Moxico: ['Luau', 'Luena', 'Cangamba', 'Cazombo'],
		Namibe: ['Moçâmedes', 'Porto Alexandre'],
		Uíge: ['Uíge', 'Negage', 'Songo'],
		Zaire: ['Soio', 'Mbanza Kongo', 'Nzeto'],
	},
];
const sierraLeoneStates: state[] = [
	{
		Eastern: [
			'Kenema',
			'Baiama',
			'Kijehun',
			'Tosu',
			'Gulaoma',
			'Petema',
			'Gombo',
			'Sefadu',
			'Koidu',
			'Pujehun',
			'Koeyor',
		],
		Northern: [
			'Makeni',
			'Tasso',
			'Sangbalima',
			'Pulunmant',
			'Tasoku',
			'Port Loko',
		],
		Southern: [
			'Bo',
			'Manjama',
			'Mendewa',
			'Gbomboma',
			'Semabu',
			'York',
			'Domboko',
			'Bonthe',
		],
		'Western Area': [
			'Freetown',
			'Mirimboe',
			'Congo Town',
			'Hill Station',
			'Cockerill',
			'Murray Town',
			'Lumley',
			'Wilberforce',
			'Aberdeen',
			'Tower Hill',
			'Kola Tree',
			'Kissy Bassah',
			'Coldbath Town',
			'Allen Town',
			'Calaba Town',
			'Lower Allen Town',
			'Pamuronko',
			'Old Wharf Wellington',
			'Kola Town',
			'Ro-poti',
			'Kissy',
			'Fisher Lane',
			'Rokupa',
			'Wellington',
			'Mayenkineh',
			'Wellington Bassah',
			'Mount Aureol',
			'Kortright',
			'Bassa',
			'Leicester',
			'Jui',
			'Grafton',
			'Masantigi',
			'Middle Town',
			'Timmani Town',
			'Hastings',
			'Johntop',
			'Bassu Town',
			'Congo',
			'Mongonbuh',
			'Devil Hole',
			'Kossoh Town',
			'Hake Town',
			'Fakina',
			'Jibima',
			'Pa Lokko',
			'Sukuma Town',
			'Mano',
			'Koba',
			'Cole Town',
			'Kani',
			'Yams Farm',
			'Susu Town',
			'Stones Town',
			'Gendima',
			'Fakai',
			'Samuel Town',
			'Jungalau',
			'Binni',
			'Kosso Town',
			'Depea Water',
			'Kottopema',
			'Ro-bungba',
			'Lumpa',
			'Bure Town',
			'Kakanda Town',
			'Benguema',
			'Rokel',
			'Samahun',
			'Juite Town',
			'Campbell Town',
			'Paw Paw',
			'Waterloo',
			'Moyah',
			'Loko Fakai',
		],
	},
];
const togoStates: state[] = [
	{
		Savanes: [
			'Dapaong',
			'Cinkassé',
			'Tandjouaré',
			'Mandouri',
			'Gando',
			'Bogou',
			'Bombouaka',
			'Korbongou',
			'Lokpano',
			'Kpendjal',
		],
		Kara: [
			'Kara',
			'Niamtougou',
			'Bassar',
			'Kpagouda',
			'Kabou',
			'Bandjéli',
			'Natchibore',
			'Pya',
			'Sarakawa',
			'Bafilo',
			'Défalé',
			'Tcharé',
			'Kétao',
		],
		Central: [
			'Sokodé',
			'Tchamba',
			'Sotouboua',
			'Blitta',
			'Pagala',
			'Adjengré',
			'Aouda',
			'Alédjo',
			'Kadambara',
			'Kambolé',
			'Kparatao',
			'Lanbangou',
			'Tchaoudjo',
		],
		Plateaux: [
			'Atakpamé',
			'Kpalimé',
			'Badou',
			'Anié',
			'Notsé',
			'Agou',
			'Amlamé',
			'Kévé',
			'Womé',
			'Aklowa',
			'Akparé',
			'Danyi',
			'Elavagnon',
			'Gbadi-N',
			'Kugna',
			'Kamina',
			'Morétan',
			'Adéta',
			'Agu-Gadjepe',
		],
		Maritime: [
			'Lomé',
			'Tsévié',
			'Aneho',
			'Tabligbo',
			'Vogan',
			'Aflao Gakli',
			'Agbodrafo',
			'Baguida',
			'Davie',
			'Mamakope',
			'Mission Tové',
			'Noépé',
			'Assahoun',
			'Bassar-Agbalepedo',
			'Djakpata',
			'Kossi-Agbalepedo',
		],
	},
];
const swedenStates: state[] = [
	{
		Stockholm: [
			'Stockholm',
			'Solna',
			'Sundbyberg',
			'Lidingö',
			'Nacka',
			'Haninge',
			'Botkyrka',
			'Tyresö',
			'Värmdö',
			'Huddinge',
			'Järfälla',
			'Täby',
			'Upplands Väsby',
			'Sollentuna',
			'Danderyd',
			'Vaxholm',
		],
		Uppsala: [
			'Uppsala',
			'Enköping',
			'Bålsta',
			'Tierp',
			'Östhammar',
			'Knivsta',
			'Heby',
		],
		Södermanland: [
			'Eskilstuna',
			'Nyköping',
			'Oxelösund',
			'Trosa',
			'Strängnäs',
			'Katrineholm',
			'Flen',
			'Vingåker',
			'Gnesta',
		],
		Östergötland: [
			'Linköping',
			'Norrköping',
			'Motala',
			'Mjölby',
			'Söderköping',
			'Finspång',
			'Vadstena',
			'Kisa',
			'Sävsjö',
		],
		Jönköping: [
			'Jönköping',
			'Huskvarna',
			'Värnamo',
			'Nässjö',
			'Gislaved',
			'Vetlanda',
			'Tranås',
			'Eksjö',
			'Sävsjö',
		],
		Kronoberg: [
			'Växjö',
			'Ljungby',
			'Älmhult',
			'Alvesta',
			'Tingsryd',
			'Markaryd',
			'Uppvidinge',
		],
		Kalmar: [
			'Kalmar',
			'Västervik',
			'Oskarshamn',
			'Nybro',
			'Mönsterås',
			'Högsby',
			'Torsås',
			'Mörbylånga',
			'Emmaboda',
		],
		Gotland: [
			'Visby',
			'Hemse',
			'Slite',
			'Klintehamn',
			'Roma',
			'Lärbro',
			'Fårösund',
			'Burgsvik',
		],
		Blekinge: ['Karlskrona', 'Karlshamn', 'Ronneby', 'Sölvesborg', 'Olofström'],
		Skåne: [
			'Malmö',
			'Helsingborg',
			'Lund',
			'Kristianstad',
			'Hässleholm',
			'Ängelholm',
			'Landskrona',
			'Trelleborg',
			'Ystad',
			'Eslöv',
			'Höganäs',
			'Simrishamn',
			'Klippan',
		],
		Halland: ['Halmstad', 'Varberg', 'Kungsbacka', 'Falkenberg', 'Laholm'],
		'Västra Götaland': [
			'Gothenburg',
			'Trollhättan',
			'Uddevalla',
			'Skövde',
			'Borås',
			'Vänersborg',
			'Kungälv',
			'Alingsås',
			'Lidköping',
			'Falköping',
			'Lerum',
			'Partille',
			'Skara',
			'Mölndal',
			'Åmål',
			'Strömstad',
		],
		Värmland: [
			'Karlstad',
			'Kristinehamn',
			'Arvika',
			'Hagfors',
			'Säffle',
			'Sunne',
			'Filipstad',
			'Torsby',
			'Eda',
			'Grums',
		],
		Örebro: [
			'Örebro',
			'Kumla',
			'Hallsberg',
			'Karlskoga',
			'Lindesberg',
			'Askersund',
			'Laxå',
			'Nora',
			'Degerfors',
		],
		Västmanland: [
			'Västerås',
			'Köping',
			'Sala',
			'Arboga',
			'Fagersta',
			'Hallstahammar',
			'Surahammar',
		],
		Dalarna: [
			'Falun',
			'Borlänge',
			'Ludvika',
			'Mora',
			'Hedemora',
			'Avesta',
			'Säter',
			'Rättvik',
			'Leksand',
		],
		Gävleborg: [
			'Gävle',
			'Sandviken',
			'Söderhamn',
			'Hudiksvall',
			'Bollnäs',
			'Ljusdal',
			'Ockelbo',
			'Nordanstig',
		],
		Västernorrland: [
			'Sundsvall',
			'Örnsköldsvik',
			'Härnösand',
			'Sollefteå',
			'Kramfors',
			'Timrå',
			'Ånge',
		],
		Jämtland: [
			'Östersund',
			'Strömsund',
			'Sveg',
			'Hammarstrand',
			'Hede',
			'Järpen',
			'Krokom',
			'Åre',
			'Bräcke',
		],
		Västerbotten: [
			'Umeå',
			'Skellefteå',
			'Lycksele',
			'Vännäs',
			'Robertsfors',
			'Vilhelmina',
			'Storuman',
			'Malå',
			'Åsele',
		],
		Norrbotten: [
			'Luleå',
			'Piteå',
			'Boden',
			'Kiruna',
			'Haparanda',
			'Kalix',
			'Gällivare',
			'Arvidsjaur',
			'Jokkmokk',
			'Överkalix',
		],
	},
];
const ukStates: state[] = [
	{
		England: [
			'London',
			'Manchester',
			'Birmingham',
			'Liverpool',
			'Leeds',
			'Sheffield',
			'Bristol',
			'Newcastle',
			'Nottingham',
			'Leicester',
			'Brighton',
			'Oxford',
			'Cambridge',
			'York',
			'Reading',
			'Cardiff',
			'Bath',
			'Coventry',
			'Stoke-on-Trent',
			'Derby',
			'Southampton',
			'Portsmouth',
			'Exeter',
			'Plymouth',
			'Bournemouth',
			'Blackpool',
			'Milton Keynes',
			'Swindon',
			'Norwich',
			'Peterborough',
			'Ipswich',
		],
		Scotland: [
			'Glasgow',
			'Edinburgh',
			'Aberdeen',
			'Dundee',
			'Inverness',
			'Stirling',
			'Perth',
			'Ayr',
			'Dumfries',
			'Lerwick',
		],
		Wales: ['Cardiff', 'Swansea', 'Newport', 'Wrexham', 'Bangor', 'St Davids'],
		NorthernIreland: [
			'Belfast',
			'Londonderry',
			'Newry',
			'Armagh',
			'Lisburn',
			'Bangor',
		],
	},
];

export const countryList: CountryList = {
	Ghana: ghanaStates,
	Nigeria: nigeriaStates,
	Tanzania: tanzaniaStates,
	Rwanda: rwandaStates,
	Namibia: namibiaStates,
	Angola: angolaStates,
	'Sierra Leone': sierraLeoneStates,
	Togo: togoStates,
	Sweden: swedenStates,
	UK: ukStates,
};
