import { routes } from '../api/routes';
import { useQuery } from '@tanstack/react-query';

const useSummary = () => {
	const { data, isLoading } = useQuery(['getSummaries'], routes.getSummaries);

	const getSummaryById = (id?: number) =>
		useQuery(['getConsultationSummaryById', id], () => routes.getSummary(id), {
			enabled: !!id,
		});
	const getPrescriptionById = (id?: number) =>
		useQuery(
			['getPrescriptions', id],
			() => routes.getPrescriptionsByConsultation(id),
			{
				enabled: !!id,
			}
		);
	const getLabRequestsById = (id?: number) =>
		useQuery(['getLabresult', id], () => routes.getLabResults(id), {
			enabled: !!id,
		});

	const getIsPaidById = (id?: number)=>
		useQuery(['getIsPaid', id], ()=> routes.getIsPaid(id),{
			enabled: !!id,
		});

	const getFollowUpSummary = ( consultationId?: number)=>
		useQuery(['getFollowupSumary', consultationId], ()=> routes.getFollowUpSummary(consultationId),{
			enabled: !!consultationId,
		})
	const getConsultationFollowup = (followupID? : number )=>
		useQuery(['getConsultationFollowup', followupID], ()=> routes.getConsultationReservated(followupID),{
			enabled: !!followupID
		})

/*	const getReceiptById = (id?: number) =>
		useQuery(['getReceiptById', id], () => routes.getReceipt, {
			enabled: !!id,
		});*/

		// const updateFollowUp = (id?: number) =>
		// 	useQuery(['updateFollowup', id],()=> routes.updateFollowUp(id),{
		// 		enabled: !!id,
		// 	})

	const getFollowUpById = (id?: number) =>
		useQuery(
		['getFollowUpById', id], 
		() => routes.getFollowUp(id), {
			enabled: !!id,
		});
		const getMedicalReportByConsultancyId = (id?: number) =>
			useQuery(['getConsultancyById', id], () => routes.getConsultancyById(id), {
				enabled: !!id,
			});

	return {
		summaries: data,
		loading: isLoading,
		getPrescriptionById,
		getSummaryById,
		getLabRequestsById,
		getIsPaidById,
		getFollowUpSummary,
		getFollowUpById,
		getConsultationFollowup,
		getMedicalReportByConsultancyId
		
	};
};

export default useSummary;
